<template lang="pug">
	.main-wrapper.protocolo-listar
		header.main-wrapper-header
			.p-grid.p-align-center
				.p-col-3
					Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='$router.go(-1)')
				.p-col-9.ta-right
					h1.text-header.text-secondary MedClub /
						<router-link :to='{ name: "lotes" }'> Protocolo</router-link> /
						<b>Guias</b>

		div(v-show='finalizar')

			Finalizar(ref='finalizar')

		div(v-show='!finalizar')
			Panel.filters(header='Selecione o período' v-show='!waiting')
				.p-grid.p-fluid
					.p-col-12.p-md-2
						label.form-label Data inicial:
						.p-inputgroup
							Calendar(:class="{ active: id == 0 }" v-model='filters.dt_execucao_inicial' dateFormat="dd/mm/yy" :locale="ptbr" :maxDate='filters.dt_execucao_final'
								:manualInput='false' :touchUI='windowInnerWidth < 576' :readonly='id != 0')
							Button.p-button-warning(icon='jam jam-rubber' v-tooltip.top="'Limpar'" @click='filters.dt_execucao_inicial = null;')

					.p-col-12.p-md-2
						label.form-label Data final:
						.p-inputgroup
							Calendar(:class="{ active: id == 0 }" v-model='filters.dt_execucao_final' dateFormat="dd/mm/yy" :locale="ptbr" :minDate='filters.dt_execucao_inicial'
								:manualInput='false' :touchUI='windowInnerWidth < 576' :readonly='id != 0')
							Button.p-button-warning(icon='jam jam-rubber' v-tooltip.top="'Limpar'" @click='filters.dt_execucao_final = null;')

					.p-col-12.p-md-2(v-show='id == 0')
						label.form-label Tipo de faturamento:
						Dropdown(v-model='filters.ie_tipo_agenda' :options='options.tipo_agenda'
							optionLabel='label' optionValue='key' dataKey='key' placeholder='TODOS')

					.p-col-12.p-md-4
						label.form-label Tipo de guia:
						Dropdown(v-model='filters.ie_tipo_guia' :options='options.tipo_guia'
							optionLabel='label' optionValue='key' dataKey='key' placeholder='TODOS')
					
					.p-col-12.p-md-2
						Button.p-button-text.my-4(
							:disabled="!filters.dt_execucao_final || !filters.dt_execucao_inicial"
							label="Pesquisar"
							icon='jam jam-search'
							@click='applyFilters()')

			.mt-2

				ProgressBar(v-if='waiting' mode="indeterminate")
				div(v-else-if='! filters.cd_estabelecimento')
					p.ta-center.text-secondary(style='margin-top: 40px;') Por favor, selecione um estabelecimento.
				div(v-else-if='list.length == 0')
					p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.
				div(v-else)
					.ta-right.my-2(v-if='selected.length > 0')
						Button(:label="`${ id != 0 ? 'Atualizar' : 'Gerar' } lote`" :icon="id != 0 ? 'jam jam-check' : 'jam jam-plus'" @click="finalizarprotocolo()")
					.p-col-12.p-md-6(v-show='id == 0')
					.p-grid
						.p-col-12
							//p.text-soma <b>Selecionados:</b> {{ selected ? selected.length : 0 }}
							p.text-soma <b>Total Guia(s):</b> {{ value_selected }}
					Panel.datatable(header='Lista de guias')
						DataTable(:value="list" :filters="filters_table" :paginator='true' :rows='perPage' dataKey="id" :selection.sync='selected' :metaKeySelection='false')

							Column(selectionMode='multiple' headerStyle='width: 3em')
							Column(headerStyle='width: 10%;' bodyStyle='text-align: center;' field='cd_guia_procedimentos' filterMatchMode="contains" header='Cód. guia' sortable)
								
								template(#filter)
									InputText(type="text" v-model="filters_table['cd_guia_procedimentos']" class="p-column-filter" placeholder="Guia")

								template(#body='props')
									span {{ props.data.cd_guia_procedimentos }}
									div(v-if="!props.data.ie_grupo_limitado")
										span(v-if="props.data.cd_tf_cd_empresa") <br> Guia Empresa
										span(v-else) <br> Guia Pessoal

							Column(headerStyle='width: 10%;' bodyStyle='text-align: center;' field='date_unix' header='Data Agenda' sortable)
								template(#filter)
									InputText(type="text" v-model="filters_table['dt_agenda']" class="p-column-filter" placeholder="Data")
								template(#body='props')
									span {{props.data.dt_agenda}}
							
							Column(headerStyle='width: 10%;' bodyStyle='text-align: center;' header='Data Execução' sortable)
								template(#filter)
									InputText(type="text" v-model="filters_table['dt_execucao']" class="p-column-filter" placeholder="Data")
								template(#body='props')
									span {{ props.data.dt_execucao }}

							Column(headerStyle='width: 25%;' header='Nome do Paciente' field='nm_paciente' filterMatchMode="contains" sortable)
							Column(headerStyle='width: 15%;' field='nm_procedimento_filter' filterMatchMode="contains" sortable)
								template(#header) Procedimento
								template(#body='props')
									span {{ props.data.nm_procedimento }}
									span(v-if='props.data.nm_especialista') <br><b>Dr(a). {{ props.data.nm_especialista }} ({{ props.data.nm_conselho }} {{ props.data.nr_conselho }})</b>
								template(#filter)
									InputText(type="text" v-model="filters_table['nm_procedimento_filter']" class="p-column-filter" placeholder="Procedimento") 
							Column(headerStyle='width: 10%;' bodyStyle='text-align: center;' field='valor_repasse_f' header='Repasse')
							Column(headerStyle='width: 10%;' bodyStyle='padding: 0;' header='Situação')
								template(#body='props')
									.cell(:class="{\
										ex: props.data.nm_situacao == 'EXECUTADA',\
										a: props.data.nm_situacao == 'EMITIDA',\
										c: props.data.nm_situacao == 'CANCELADA'\
									}") {{ props.data.nm_situacao }}
							Column(headerStyle='width: 10%;' header='Tipo' bodyStyle='text-align: center;' field='nm_guia_tipo' filterMatchMode="contains" sortable)
								template(#body='props')
									span {{ props.data.nm_guia_tipo }}
									// span(v-if='props.data.cd_tf_cd_empresa') ({{ props.data.cd_tf_cd_empresa }})
								template(#filter)
									InputText(type="text" v-model="filters_table['nm_guia_tipo']" class="p-column-filter" placeholder="Tipo")
						
					.p-grid
						.p-col-12
							//p.text-soma <b>Selecionados:</b> {{ selected ? selected.length : 0 }}
							p.text-soma <b>Total Guia(s):</b> {{ value_selected }}
</template>

<style lang="scss">
.protocolo-listar {
	.datatable {
		@media all and (max-width: 576px) {
			display: none;
		}
		.cell {
			padding: 10px 0;
			text-align: center;
			font-weight: 700;
		}
		.p-highlight {
			.cell { background-color: #007ad9 !important; }
		}
	}
	.filters {
		.p-disabled {
			opacity: 1;
		}
		.p-inputtext[readonly=readonly], .p-disabled .p-inputtext, .p-disabled .p-dropdown-trigger {
			color: #333 !important;
			background-color: #eee;
			font-weight: 700;
		}
		.p-calendar.active > .p-inputtext {
			background-color: #fff;
			font-weight: 400;
		}
		.p-disabled .p-dropdown-trigger {
			color: #eee !important;
		}
	}
	.text-soma {
		margin-bottom: 4px;
		margin-right: 10px;
		display: inline-block;
		background-color: #f4f4f4;
		padding: 10px;
	}
}

</style>

<script>
import Panel from 'primevue/panel'
import Dropdown from 'primevue/dropdown'
import Calendar from 'primevue/calendar'
import ProgressBar from 'primevue/progressbar'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import Tooltip from 'primevue/tooltip'
import Finalizar from './ListarGuiasFinalizar'
import { Estabelecimento, GuiaEletronica, Agenda } from '../../middleware'
import { pCalendarLocale_ptbr, fixTelefoneFormat, formatDateToBR } from './../../utils'
import moment from 'moment'
import wsConfigs from './../../middleware/configs'

export default {
	components: { Panel, Dropdown, InputText, Calendar, ProgressBar, DataTable, Column, Button, Finalizar, Tooltip },
	directives: { tooltip: Tooltip },
	created () {
		this.waiting = true
		this.waiting_especialistas = true
		Agenda.getEspecialistasAgendados().then(response => {
			this.waiting_especialistas = false
			if (response.status == 200) {
				let mem = {}
				response.data.forEach(e => {
					if (! mem[e.cd_especialista]) {
						mem[e.cd_especialista] = true
						this.options.especialistas.push({ text: e.nm_especialista, value: e.cd_especialista })
					}
				})
				this.options.especialistas.sort((a, b) => a.text > b.text ? 1 : -1)
				this.options.especialistas.unshift({ text: 'TODOS', value: null })
			}
			Estabelecimento.findAll().then(response => {
				this.waiting = false
				if (response.status == 200) this.options.estabelecimentos = response.data

				if (! isNaN(this.$route.params.id)) this.id = parseInt(this.$route.params.id)
				if (this.id != 0) this.finalizar = true
			})
		})
	},
	data () {
		let idAtualEstabelecimento = JSON.parse(localStorage.getItem('usuario'))["idxCurrentEstabelecimento"]
		let atualEstabelecimento = JSON.parse(localStorage.getItem('usuario'))["estabelecimentos"][idAtualEstabelecimento].id
		return {
			id: 0,
			ptbr: pCalendarLocale_ptbr,
			windowInnerWidth: window.innerWidth,
			list: [],
			selected: [],
			value_selected: 0,
			filters_table: {},
			filters: {
				cd_estabelecimento: atualEstabelecimento,
				dt_execucao_inicial: moment()._d,
				dt_execucao_final: moment()._d,
				cd_guia_situacao: 5,
				cd_pagamento: null,
				ie_tipo_agenda: null,
                ie_retorno: false,
				cd_especialista: null,
				ie_sem_estorno: true,
				ie_tipo_guia: null
			},
			options: {
				estabelecimentos: [],
				especialistas: [],
				tipo_agenda: [
					{ key: null, label: 'TODOS' },
					{ key: 'C', label: 'Consulta' },
					{ key: 'E', label: 'Exame' }
				],
				tipo_guia: [
					{ key: null, label: 'TODOS' },
					{ key: 'P', label: 'Guia Pessoal' },
					{ key: 'E', label: 'Guia Empresa' }
				]
			},
			waiting: false,
			waiting_especialistas: false,
			perPage: wsConfigs.paginator_perPage,
			finalizar: false
		}
	},
	methods: {
		filterDate(value, filter) {
			if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
				return true;
			}

			if (value === undefined || value === null) {
				return false;
			}

			return value === this.formatDate(filter);
		},
		formatDate(date) {
			let month = date.getMonth() + 1;
			let day = date.getDate();

			if (month < 10) {
				month = '0' + month;
			}

			if (day < 10) {
				day = '0' + day;
			}

			return date.getFullYear() + '-' + month + '-' + day
		},
		formatPrice (val) {
			return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val)
		},
		applyFilters (cd_pagamento=null) {

			if (cd_pagamento) this.filters.cd_pagamento = cd_pagamento

			if (! this.filters.cd_estabelecimento) {
				this.list = []
				return 0
			}

			let params = {}
			Object.keys(this.filters).forEach((key) => {
				if (key.substr(0, 3) == 'dt_') {
					if (moment(this.filters[key], 'DD/MM/YYYY').isValid())
						params[key] = moment(this.filters[key], 'DD/MM/YYYY').format('YYYY-MM-DD')
				} else if (this.filters[key] || typeof this.filters[key] === 'boolean') params[key] = this.filters[key]
			})
			return this.getList(params)
		},
		getList (params) {
            this.selected = []
			this.waiting = true
			return GuiaEletronica.findAll(params, { ignorarEstabelecimento: true }).then(response => {
				if (response.status == 200) {
					const data = response.data.map(entry => {
						if (entry.dt_agenda) {
							entry.date_unix = moment(entry.dt_agenda).unix()
							entry.dt_agenda = moment(entry.dt_agenda).format('DD/MM/YYYY')
						} 
						entry.nm_paciente_f = (entry.nm_paciente == null) ? entry.nm_paciente : entry.nm_paciente.split(" ")[0]
						entry.ie_tipo_agenda_f = (entry.ie_tipo_agenda_f == 'E') ? 'EXAME' : 'CONSULTA'
						entry.nm_guia_tipo_f = (entry.nm_guia_tipo == null) ? entry.nm_guia_tipo : entry.nm_guia_tipo.split(" ")[0]
                        entry.nm_procedimento_filter = `${entry.nm_procedimento} ${entry.nm_especialista || ''}`
						entry.dt_execucao = formatDateToBR(entry.dt_execucao, true)
						if (entry.hr_agenda) {
							let aux = entry.hr_agenda.split(':')
							entry.hr_agenda_f = `${ aux[0] }:${ aux[1] }`
						}
						entry.valor_repasse_f = this.formatPrice(entry.valor_repasse)
						if (entry.nr_telefone_paciente) entry.nr_telefone_paciente_f = fixTelefoneFormat(entry.nr_telefone_paciente)
						return entry
					})
					this.list = data
					// if (! this.filters.cd_pagamento) {
					// 	window.setTimeout(() => {
					// 		let input = document.querySelector('.p-datatable-thead .p-checkbox input')
					// 		if (input) if (! input.checked) input.click()
					// 	})
					// }
				}
				this.waiting = false
				return 0
			})
		},
		onPage (ev) {
			this.paginator.page = ev.page + 1
			this.applyFilters()
		},
		finalizarprotocolo () {
			this.finalizar = true
		},
	},
	watch:{
		selected() {
            let total = this.selected.reduce((acc, cur) => acc + cur.valor_repasse, 0);
            this.value_selected = this.formatPrice(total);
		}
	}
}
</script>
